.Recruit {
    width: auto;
    background-color: #282c34;
    color: white;
    padding: 5vh;
}

.Recruit > a {
    color: white;
}

.Recruit > * > a {
    color: white;
}